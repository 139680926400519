const R = require('ramda')

const scheme = process.env.NEXT_PUBLIC_MEDSHR_URL_SCHEME || 'http'
const baseHostName =
  process.env.NEXT_PUBLIC_MEDSHR_BASE_HOSTNAME || 'medshr.local'
const hostName = process.env.NEXT_PUBLIC_MEDSHR_HOSTNAME || `en.${baseHostName}`
const dashHostName =
  process.env.NEXT_PUBLIC_MEDSHR_DASH_HOSTNAME || `dashboards.${baseHostName}`
const graphqlHostName =
  process.env.NEXT_PUBLIC_MEDSHR_GRAPHQL_HOSTNAME || `gql.${baseHostName}`
const cproxyHostName =
  process.env.NEXT_PUBLIC_CACHING_PROXY_HOSTNAME || `cproxy.${baseHostName}`
const cmsHostName =
  process.env.NEXT_PUBLIC_MEDSHR_CMS_HOSTNAME || `cms.${baseHostName}`
const pdfHostName =
  process.env.NEXT_PUBLIC_MEDSHR_PDF_HOSTNAME || `print.${baseHostName}`
const imagesHostName =
  process.env.NEXT_PUBLIC_MEDSHR_IMAGES_HOSTNAME || `images.${baseHostName}`
const liveHostName =
  process.env.NEXT_PUBLIC_MEDSHR_LIVE_HOSTNAME || `live.${baseHostName}`
const rtmpsHostName =
  process.env.NEXT_PUBLIC_MEDSHR_RTMPS_HOSTNAME || `rtmp.${baseHostName}`

const version =
  process.env.NEXT_PUBLIC_MEDSHR_VERSION ||
  process.env.NEXT_PUBLIC_SENTRY_RELEASE

// const firebase = fs.existsSync('/private/config/firebase.json')
//   ? fs.readFileSync('/private/config/firebase.json')
//   : undefined
const firebase = undefined

const ga = R.prop(process.env.NEXT_PUBLIC_MEDSHR_ENVIRONMENT, {
  staging: {
    id: 'G-LKTVPQ54SB',
  },
  production: {
    id: 'G-7X4SPCKDLH',
  },
})

module.exports.publicRuntimeConfig = {
  version,
  env: process.env.NEXT_PUBLIC_MEDSHR_ENVIRONMENT,
  tracesSampleRate:
    typeof process.env.NEXT_PUBLIC_MEDSHR_TRACE_SAMPLE_RATE !== 'undefined'
      ? parseFloat(process.env.NEXT_PUBLIC_MEDSHR_TRACE_SAMPLE_RATE)
      : undefined,
  debug: /local|staging/.test(process.env.NEXT_PUBLIC_MEDSHR_ENVIRONMENT),
  debugLog: /local/.test(process.env.NEXT_PUBLIC_MEDSHR_ENVIRONMENT),
  api: {
    internalUrl:
      process.env.NEXT_PUBLIC_MEDSHR_ENVIRONMENT === 'local'
        ? `${scheme}://${hostName}`
        : `http://http-backend-api`,
    url: `${scheme}://${hostName}`,
    assumeAuthed: !!process.env.ASSUME_AUTH,
  },
  baseHostName,
  dashUrl: `${scheme}://${dashHostName}`,
  pdfUrl: `${scheme}://${pdfHostName}`,
  liveUrl: `${scheme}://${liveHostName}`,
  rtmpsUrl: `rtmps://${rtmpsHostName}`,
  routerName: process.env.MEDSHR_ROUTER_NAME,
  hostName,
  mainSiteUrl: `${scheme}://${hostName}`,
  graphqlUrl: `${scheme}://${graphqlHostName}/v1/graphql`,
  cmsHostName,
  cmsUrl: `${scheme}://${cmsHostName}`,
  imagesHostName,
  imagesUrl: `${scheme}://${imagesHostName}`,
  cproxyUrl: `${scheme}://${cproxyHostName}`,
  sentry: {
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
    hideSourceMaps: true,
  },
  branch: {
    key: process.env.NEXT_PUBLIC_BRANCH_KEY,
    url_prefix: process.env.NEXT_PUBLIC_BRANCH_URL || 'https://medshr.it',
  },
  medshr_analytics: {
    url: process.env.NEXT_PUBLIC_MEDSHR_ANALYTICS_URL,
  },
  facebook: {
    app_id: process.env.NEXT_PUBLIC_FACEBOOK_APP_ID,
    pixel_id: process.env.NEXT_PUBLIC_FACEBOOK_PIXEL_ID,
  },
  linkedin: {
    pixel_id: process.env.NEXT_PUBLIC_LINKEDIN_PIXEL_ID,
  },
  fullStory: {
    orgId: process.env.NEXT_PUBLIC_FULLSTORY_ORG_ID,
    devMode:
      ['local', 'staging', 'production'].indexOf(
        process.env.NEXT_PUBLIC_MEDSHR_ENVIRONMENT ?? ''
      ) === -1,
  },
  realtime: !process.env.LOCAL_STAGING,
  firebase,
  ga,
  recaptcha: {
    key: process.env.NEXT_PUBLIC_RECAPTCHA_KEY,
  },

  gtag_ids:
    process.env.NEXT_PUBLIC_MEDSHR_ENVIRONMENT === 'production'
      ? ['AW-944325966']
      : [],
  gtag_conversion_event_ids:
    process.env.NEXT_PUBLIC_MEDSHR_ENVIRONMENT === 'production'
      ? {
          docsearch_joined: 'AW-944325966/XQZhCJatjuADEM6KpcID',
        }
      : {},
}
